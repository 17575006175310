<template>
  <div class="tableBox">
    <my-table :tableData=state.Notice v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner">
      <template v-slot:tabel-column>
        <el-table-column label="合同类型" align="center" prop="typeName" />
        <el-table-column label="下级数量" align="center" prop="contractCount" />
        <el-table-column label="状态" align="center">
          <template #default="scope">
            <div class="dp-fc status" :class="'status' + scope.row.status">
              <div class="w-6 h-6 icon mr-9"></div>
              <div class="w-70 ta-l">{{ scope.row.status == 1 ? '使用中' : '已停用' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button type="text" v-if="scope.row.status == 0" @click="updateClick(scope.row, 1)">开启</el-button>
            <el-button type="text" v-if="scope.row.status == 1" @click="updateClick(scope.row, 0)">停用</el-button>
            <el-button type="text" @click="deleteClick(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </my-table>
  </div>
  <operations ref="operationsRef" @Emit="getDataList()"></operations>
</template>

<script setup>
import { ref, unref, reactive } from 'vue'
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import qs from "qs";
import myTable from '@/components/tables/table.vue'
import operations from "./professionType/operations.vue";
const operationsRef = ref()
const loading = ref(false);//loading 显示
const state = reactive({
  Notice: [],//表格数据
})
const getDataList = () => {
  state.Notice = []
  loading.value = true
  queryResourceTable('/initiate-contract/workType/all', {}).then((res) => {
    state.Notice = res.data
    loading.value = false;
  }).catch((err) => {
    if (err.message != 'stop')
      loading.value = false;
  })
}
getDataList()
// 删除点击
const deleteClick = (row) => {
  handleCofirm().then(() => {
    httpToken({
      method: "post",
      url: '/initiate-contract/workType/del',
      data: qs.stringify({
        ids: row.id
      })
    }).then(() => {
      getDataList()
    })
  }).catch(() => {
    handleMessage('已取消删除', 'info')
  })
}
// 修改业务类型状态
const updateClick = (row, status) => {
  handleCofirm('确认更改业务类型状态？').then(() => {
    loading.value = true
    httpToken({
      method: "post",
      url: '/initiate-contract/workType/update',
      data: {
        id: row.id,
        status: status
      }
    }).then(() => {
      getDataList()
    }).catch((err) => {
      loading.value = false;
    })
  }).catch(() => {
    handleMessage('已取消删除', 'info')
  })
}
const addType = () => {
  unref(operationsRef).show()
}
defineExpose({
  addType,
});
</script>

<style lang="scss" scoped>
::v-deep .status {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #BFBFBF;

  .icon {
    margin-top: 2px;
    width: 5px;
    height: 5px;
    background: #BFBFBF;
    border-radius: 50%;
  }

}

::v-deep .status1 {
  color: #1890FF;

  .icon {
    background: #1890FF;
  }
}

.tableBox {
  padding: 13px 20px;
}

.mt-168 {
  margin-top: 168px;
}
</style>