import axios from 'axios';
import { httpToken } from "@/utils/request";
let CancelToken = axios.CancelToken;
let cancel;
// 取消上一次请求调用
export const queryResourceTable = function (url,data) {
    // console.log('cancel',cancel)
    if(cancel)
        cancel('stop');
    console.log('data:', data)
    return httpToken({
        url: url,
        method: 'post',
        data:data,
        cancelToken: new CancelToken(function (c) {
            cancel = c;
            // console.log(c,'ccccccc')
        })
    });
};