
import { defineComponent } from "vue"
export default defineComponent({
  props: ['tableData','height'],
  setup(props, ctx) {
    const selectAll = (row) => {
      ctx.emit('selectAll',row)
    }
    console.log('tableData',props.tableData)
    return {
      selectAll,
    }
  }
})
