<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="550px">
    <div class="dp-f" style="min-height: 200px;padding-top: 30px;">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="100px"
        class="demo-ruleForm w100" label-position="left">
        <el-form-item label="输入分类名称" prop='typeName'>
          <el-input v-model="ruleForm.typeName" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入分类名称" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton width='1000' height='40' style="border-radius: 4px;" title="确定" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref,unref, defineEmits } from 'vue'
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisibletitle = ref();
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const state = reactive({
  
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  typeName: '',//分类名称
})
const rules = reactive({//from 表单校验
  typeName: [
    { required: true, message: '请输入分类名称', trigger: 'blur' },
  ],
})
const show = (() => {
  ruleForm.typeName=''
  dialogVisibletitle.value = '新增业务类型'
  dialogVisible.value = true
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      if (dialogVisibletitle.value === '新增业务类型') {
        dataGeneration(ruleForm)
      } 
    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
const dataGeneration = ((json, url = '/initiate-contract/workType/add') => {
  console.log('url',url)
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    dialogloading.value = false
    dialogVisible.value = false
    emit('Emit')
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label{
  display: none;
}
</style>