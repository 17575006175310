import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    onSelectionChange: _ctx.selectAll,
    "row-style": {height: '60px'},
    "header-row-style": {height: '60px'},
    "header-cell-style": { background: 'var(--active-alpha)', color: _ctx.config.table.color, fontWeight: _ctx.config.table.fontWeight, fontSize: _ctx.config.table.fontSize },
    data: _ctx.tableData,
    style: _normalizeStyle({width: '100%',height:_ctx.height})
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "tabel-column")
    ]),
    _: 3
  }, 8, ["onSelectionChange", "header-cell-style", "data", "style"]))
}